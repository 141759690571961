import { SyntheticEvent, ReactNode, useEffect, useState } from 'react';
import { Option, Select, SelectProps } from 'baseui/select';
import { useStyletron } from 'baseui';
import { LabelSmall } from 'baseui/typography';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { PopoverPlacement } from 'baseui/popover';
import { BaseWebHelpers } from '../helpers';

export const calculateRefinementTagOverrides = ($theme, displayInline = false) => {
  return {
    Action: {
      style: {
        marginLeft: '4px',
        marginBottom: '6px',
        color: $theme.colors.contentInverseTertiary,
      },
    },
    ActionIcon: {
      props: {
        size: '8px',
        title: 'Remove',
      },
    },
    Root: {
      style: {
        maxWidth: '300px',
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: $theme.colors.backgroundPrimary,
        color: $theme.colors.contentPrimary,
        ...BaseWebHelpers.expandBorderStyles({
          borderWidth: '2px',
          borderColor: $theme.colors.borderOpaque,
          borderStyle: 'solid',
        }),
        ...(displayInline ? { cursor: 'default' } : {}),
      },
    },
    Text: {
      style: {
        fontWeight: displayInline ? '400' : '600',
      },
    },
  };
};

export type BeSelectProps = Omit<SelectProps, 'onInputChange'> & {
  /**
   * Controls additional behavior on any input change.
   * If the input change event controls option filtering, also set `isCustomInputFilter` to true.
   */
  onInputChange?: (val: string | any) => Promise<any> | void;
  labelText?: string | ReactNode;
  isAccented?: boolean;
  /**
   * Set to true if `onInputChange` is controlling the option filtering, such as when options are fetched async via APIs
   * or a custom filter function is defined by the caller.
   */
  isCustomInputFilter?: boolean;
  popoverPlacement?: PopoverPlacement;
  dropdownMinWidth?: string;
};

export const BeSelect = ({
  overrides,
  onInputChange,
  labelText,
  isAccented,
  isCustomInputFilter,
  placeholder,
  popoverPlacement,
  dropdownMinWidth,
  ...props
}: BeSelectProps) => {
  const [css, $theme] = useStyletron();
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<readonly Option[]>([]);

  useEffect(() => {
    // todo: fix this when using it as a clearable or multiselect when removing all
    if (
      /* Verify caller isn't controlling value */
      !props.value &&
      /* Must have options available */
      props.options &&
      /* Nothing selected yet */
      !selectedItems.length &&
      /* Options are an array */
      Array.isArray(props.options) &&
      /* At least one option has isDefault: true */
      props.options.some((item) => item.isDefault)
    ) {
      // Refine to default item, don't directly set the selected item though (handled by hook)
      console.log('REFINING');
      setSelectedItems(props.options.filter((item) => item.isDefault));
    }
  }, [props.value, props.options, selectedItems]);

  const backgroundCss = isAccented
    ? {
        backgroundColor: $theme.colors['backgroundLightAccent'],
        color: $theme.colors.accent,
      }
    : {
        backgroundColor: $theme.colors.backgroundTertiary,
      };

  return (
    <>
      {labelText && (
        <LabelSmall overrides={{ Block: { style: { marginBottom: '8px' } } }} display={'block'}>
          {labelText}
        </LabelSmall>
      )}
      <Select
        maxDropdownHeight={'325px'}
        placeholder={placeholder}
        // Default to not clearable, allow props to override
        clearable={false}
        value={selectedItems.length ? selectedItems : null}
        onChange={(params) => {
          setSelectedItems(params.value);
        }}
        overrides={{
          ...(dropdownMinWidth
            ? {
                DropdownContainer: {
                  style: {
                    minWidth: dropdownMinWidth,
                  },
                },
              }
            : {}),
          Tag: { props: { overrides: { ...calculateRefinementTagOverrides($theme) } } },
          ControlContainer: {
            style: ({ $theme, $isFocused }: any) => ({
              ...($isFocused
                ? {
                    backgroundColor: $theme.colors['backgroundLightAccent'],
                  }
                : backgroundCss),
            }),
          },
          OptionContent: {
            style: ({ $selected }: any) => ({
              // Must set weight to 600, for some reason selected options force a fallback to sans-serif from Poppins otherwise
              fontWeight: $selected ? '600' : 'normal',
            }),
          },
          ClearIcon: {
            style: {
              width: '18px',
              height: '18px',
              color: '#0E2673',
            },
          },
          SelectArrow: () => <ExpandMoreRoundedIcon fontSize={'small'} />,
          ...(popoverPlacement
            ? {
                Popover: {
                  props: {
                    ...(popoverPlacement ? { placement: 'right' } : {}),
                  },
                },
              }
            : {}),
          ...overrides,
        }}
        onInputChange={
          onInputChange
            ? (event: SyntheticEvent) => {
                const target = event.target as HTMLInputElement;
                setLoading(true);
                Promise.all([onInputChange(target.value || target)]).finally(() => setLoading(false));
              }
            : undefined
        }
        isLoading={loading}
        {...props}
        /* Disable the default filtering of options when a custom input filter function is being applied
         * Generally leveraged for async queries to databases and other similar situations */
        {...(isCustomInputFilter ? { filterOptions: (options) => options } : {})}
      />
    </>
  );
};

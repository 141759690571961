import { ReactNode, useEffect, useState } from 'react';
import { RouteEnum } from '@benefeature/shared-common';
import { useRouter } from 'next/router';
import { UserRole } from '@benefeature/shared-types';
import { meetsSomeRole } from '../core/services/user-state.service';
import { useSession } from 'next-auth/react';

export type ProtectedPageProps = {
  children: ReactNode | ReactNode[] | Element | Element[];
  rolesWithAccess?: UserRole[];
  fallbackUrl?: string;
  fallbackComponent?: ReactNode;
};

/**
 * Protects a route by forcing a redirect if conditions aren't met.
 *
 * @param children
 * @param rolesWithAccess The roles which should be met.  Roles are defined as combinations of permissions and subscriptions.
 *                        Multiple different roles may be provided in an array, and if any are met by the user, access is allowed.
 * @param fallbackUrl Where the redirect takes the user upon failure to validate any role.
 * @param fallbackComponent A default component to display if access isn't allowed and a redirect isn't performed.
 * @constructor
 */
export const ProtectedPage = ({ children, rolesWithAccess, fallbackUrl, fallbackComponent }: ProtectedPageProps) => {
  const router = useRouter();
  const { data: session, status: sessionStatus } = useSession();

  // Stateful flag, prevents render of children before the useEffect hook has had a chance to check permissions
  // Prevents intermediary page state from rendering, including odd "flashes"
  const [permissionsChecked, setPermissionsChecked] = useState(false);

  // Set a default fallback URL based on authentication status
  // This is generally the login page with a redirect to the current path,
  // but if there is already an active session/user the fallback should instead be to any always-accessible path (BFR-671)
  if (!fallbackUrl) {
    if (session && session?.user?.id) {
      // Redirecting to login would likely result in a loop, push to dashboard instead
      fallbackUrl = RouteEnum.DASHBOARD;
    } else {
      // Redirecting to login seems okay
      fallbackUrl = `${RouteEnum.AUTH_LOGIN}?redirect=${encodeURIComponent(router.asPath)}`;
    }
  }

  // If the fallback URL is the same as the current path, set a safety of going back to the completely open home page
  if (router.asPath === fallbackUrl) {
    console.debug(
      ProtectedPage.name,
      'fallbackUrl',
      fallbackUrl,
      'is the same as the current path, defaulting to',
      RouteEnum.HOME
    );
    fallbackUrl = RouteEnum.HOME;
  }

  useEffect(() => {
    // If not authenticated, redirect the user to either the fallback URL or login if no fallback URL is present
    if (sessionStatus !== 'loading' && !session?.user && !fallbackComponent) {
      console.debug(ProtectedPage.name, 'found no authenticated user. Redirecting to fallback URL:', fallbackUrl);
      router.push(fallbackUrl);
    }

    // Verify the user has a set of permissions and/or subscriptions as defined by the role
    // If the user has any role with access, allow
    if (sessionStatus !== 'loading' && rolesWithAccess && !!rolesWithAccess.length) {
      if (!meetsSomeRole(rolesWithAccess, session)) {
        console.debug(
          ProtectedPage.name,
          'found authentication did not meet any roles with access. Redirecting to fallback URL:',
          fallbackUrl
        );
        router.push(fallbackUrl);
      }
    }

    if (sessionStatus !== 'loading') {
      // Only set the checked flag if the session is done loading since all checks depend on that
      setPermissionsChecked(true);
    }
  }, [session, sessionStatus, rolesWithAccess, fallbackComponent, fallbackUrl, router]);

  if (sessionStatus !== 'loading' && !session?.user) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return fallbackComponent ? <>{fallbackComponent}</> : <h1>Access not allowed</h1>;
  }

  return (
    <>
      {/* Only render children after permissions are checked */}
      {permissionsChecked ? children : null}
    </>
  );
};

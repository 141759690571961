import { ActionType } from '../../models';

export enum UIConfigActionTypes {
  TOGGLE_SIDEBAR = '[UIConfig] Toggle sidebar',
  SELECT_DASHBOARD_PEER = '[UIConfig] Select dashboard peer',
  SELECT_THEME = '[UIConfig] Select UI theme',
}

export const toggleSidebar = (payload: boolean): ActionType => ({
  type: UIConfigActionTypes.TOGGLE_SIDEBAR,
  payload,
});

export const selectDashboardPeer = (payload: string): ActionType => ({
  type: UIConfigActionTypes.SELECT_DASHBOARD_PEER,
  payload,
});

export const selectTheme = (payload: string): ActionType => ({
  type: UIConfigActionTypes.SELECT_THEME,
  payload,
});

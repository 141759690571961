import { ReactNode } from 'react';
import { markHighlights } from './general';

export enum EntityType {
  EMPLOYER = 'Employer',
  BROKER = 'Broker',
  CARRIER = 'Carrier',
}

export enum EntityTypesSingularToPluralMap {
  /* Map used to crosswalk a singular variant of an entity to the plural variant */
  EMPLOYER = 'EMPLOYERS',
  BROKER = 'BROKERS',
  CARRIER = 'CARRIERS',
}

export function entityTypeToPlural(entityType: string): string {
  return EntityTypesSingularToPluralMap[entityType?.toUpperCase()];
}

function _chooseEntityName(
  entity: any,
  cleansedNameField: string,
  baseNameField: string,
  affiliationField?: string
): string {
  // Stack all candidates in order of precedence and find the first non-null
  // Mutate each key to check for both underscore and hyphenated variants
  return [
    affiliationField ? entity?.[affiliationField?.replaceAll('_', '-')] : null,
    affiliationField ? entity?.[affiliationField?.replaceAll('-', '_')] : null,
    cleansedNameField ? entity?.[cleansedNameField?.replaceAll('_', '-')] : null,
    cleansedNameField ? entity?.[cleansedNameField?.replaceAll('-', '_')] : null,
    baseNameField ? entity?.[baseNameField?.replaceAll('_', '-')] : null,
    baseNameField ? entity?.[baseNameField?.replaceAll('-', '_')] : null,
    '',
  ].find((el) => el !== null && el !== undefined);
}

// Gets the ideal name from an entity based on precedence and first populated
export function getEntityBestName(entity: any, entityType: EntityType): string {
  switch (entityType) {
    case EntityType.EMPLOYER:
      return _chooseEntityName(entity, 'name_cleansed', 'name');
    case EntityType.BROKER:
      return _chooseEntityName(entity, 'broker_name_cleansed', 'broker_name', 'broker_affiliation');
    case EntityType.CARRIER:
      return _chooseEntityName(entity, 'carrier_name_cleansed', 'carrier_name', 'carrier_affiliation');
    default:
      return '';
  }
}

function _chooseHighlightedEntityName(
  entity: any,
  cleansedNameField: string,
  baseNameField: string,
  affiliationField?: string,
  highlightObjectAttribute?: string
): string | ReactNode[] {
  return [
    affiliationField ? markHighlights(entity, affiliationField?.replaceAll('_', '-'), highlightObjectAttribute) : null,
    affiliationField ? markHighlights(entity, affiliationField?.replaceAll('-', '_'), highlightObjectAttribute) : null,
    cleansedNameField
      ? markHighlights(entity, cleansedNameField?.replaceAll('_', '-'), highlightObjectAttribute)
      : null,
    cleansedNameField
      ? markHighlights(entity, cleansedNameField?.replaceAll('-', '_'), highlightObjectAttribute)
      : null,
    baseNameField ? markHighlights(entity, baseNameField?.replaceAll('_', '-'), highlightObjectAttribute) : null,
    baseNameField ? markHighlights(entity, baseNameField?.replaceAll('-', '_'), highlightObjectAttribute) : null,
    '',
  ].find((el) => el !== null && el !== undefined);
}

// Gets the ideal name from an entity based on precedence and first populated, with highlighting applied if possible
export function getHighlightedEntityBestName(
  entity: any,
  entityType: EntityType,
  highlightObjectAttribute: string
): string | ReactNode[] {
  switch (entityType) {
    case EntityType.EMPLOYER:
      return _chooseHighlightedEntityName(entity, 'name_cleansed', 'name', null, highlightObjectAttribute);
    case EntityType.BROKER:
      return _chooseHighlightedEntityName(
        entity,
        'broker-name-cleansed',
        'broker-name',
        'broker-affiliation',
        highlightObjectAttribute
      );
    case EntityType.CARRIER:
      return _chooseHighlightedEntityName(
        entity,
        'carrier-name-cleansed',
        'carrier-name',
        'carrier-affiliation',
        highlightObjectAttribute
      );
    default:
      return '';
  }
}

export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

export function stripPhoneNumber(phoneNumberString: string) {
  // Removes all characters from the phone number which could break a tel: link
  return phoneNumberString.replace(/\D|\+/g, '');
}

// Let the address line parameters contain numbers
// noinspection ParameterNamingConventionJS
export function generateGoogleMapsLink(
  addressLine1: string,
  addressLine2: string,
  addressCity: string,
  addressState: string,
  addressZip: string
) {
  return (
    'https://www.google.com/maps/search/?api=1&query=' +
    encodeURIComponent(
      `${addressLine1 || ''} ${addressLine2 || ''} ${addressCity || ''} ${addressState || ''} ${addressZip || ''}`
    )
  );
}

export const generateLinkedInLink = (name: string, companyName: string, role?: string): string => {
  // Parse the name, lowercasing and removing all but the first and last names
  const parsedName = name
    ?.toLowerCase()
    ?.split(' ')
    ?.filter((_, idx, words) => idx === 0 || idx === (words.length ?? 0) - 1)
    ?.join(' ');

  if (!parsedName || parsedName.length === 0 || !companyName || companyName.length === 0) {
    return '';
  }

  // Return a structured LinkedIn facet search for the name and company which is likely to produce a viable result
  return `https://linkedin.com/search/results/people?facetGeoRegion=["us%3A0"]&keywords=(${encodeURIComponent(
    parsedName
  )})%20OR%20(${encodeURIComponent(companyName)}${role ? `%20AND%20${role}` : ''})&origin=FACETED_SEARCH`;
};

export const formatBrokerListForOthers = (inputBrokers) => {
  if (!inputBrokers || inputBrokers.length === 0) {
    return [];
  }

  const reducedOtherBrokers = inputBrokers
    .filter((bkr) => bkr['broker-total-compensation-role'] === 'OTHER' || !bkr['broker-total-compensation'])
    .reduce((current, otherBroker) => {
      // Consolidate OTHER broker roles into "Others"
      if (current['broker-name-cleansed']) {
        // Integrate the broker into the object
        current['broker-total-compensation'] =
          current['broker-total-compensation'] + (otherBroker['broker-total-compensation'] || 0);
        current['broker-total-compensation-rate'] =
          current['broker-total-compensation-rate'] + (otherBroker['broker-total-compensation-rate'] || 0);
        current['broker-commission'] = current['broker-commission'] + (otherBroker['broker-commission'] || 0);
        current['broker-commission-rate'] =
          current['broker-commission-rate'] + (otherBroker['broker-commission-rate'] || 0);
        current['broker-fee'] = current['broker-fee'] + (otherBroker['broker-fee'] || 0);
        current['broker-fee-rate'] = current['broker-fee-rate'] + (otherBroker['broker-fee-rate'] || 0);
        current['OTHERS-BROKER-NAMES'] = [
          ...current['OTHERS-BROKER-NAMES'],
          {
            'broker-name': otherBroker['broker-name'],
            'broker-name-cleansed': otherBroker['broker-name-cleansed'],
            'broker-affiliation': otherBroker['broker-affiliation'],
          },
        ];
      } else {
        // Initialize the current standard attributes
        current['broker-name-cleansed'] = 'Others';
        current['broker-total-compensation'] = otherBroker['broker-total-compensation'];
        current['broker-total-compensation-rate'] = otherBroker['broker-total-compensation-rate'];
        current['broker-commission'] = otherBroker['broker-commission'];
        current['broker-commission-rate'] = otherBroker['broker-commission-rate'];
        current['broker-fee'] = otherBroker['broker-fee'];
        current['broker-fee-rate'] = otherBroker['broker-fee-rate'];
        current['OTHERS-BROKER-NAMES'] = [
          {
            'broker-name': otherBroker['broker-name'],
            'broker-name-cleansed': otherBroker['broker-name-cleansed'],
            'broker-affiliation': otherBroker['broker-affiliation'],
          },
        ];
      }

      return current;
    }, {});

  // Return any non-OTHER brokers first and then the reduced "Others" at the end
  return [
    ...inputBrokers
      .filter((bkr) => bkr['broker-total-compensation-role'] !== 'OTHER' && bkr['broker-total-compensation'])
      .sort((a, b) => {
        /* prettier-ignore */
        return a['broker-total-compensation'] > b['broker-total-compensation']
               ? -1
               : a['broker-total-compensation'] === b['broker-total-compensation']
                 ? 0
                 : 1;
      }),
    reducedOtherBrokers,
  ];
};

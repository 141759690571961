import React from 'react';
import { LabelXSmall } from 'baseui/typography';
import toNumber from 'lodash-es/toNumber';

export const FormatHelpers = {
  toPercent: (decimal, fixed = 0) => {
    return `${(decimal * 100).toFixed(fixed)}%`;
  },

  getPercent: (value, total) => {
    const ratio = total > 0 ? value / total : 0;

    return FormatHelpers.toPercent(ratio, 0);
  },

  getLocale: () => {
    return navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  },

  numberToCurrency: (num: number | string, options?: any, showLessThanOne = true): string => {
    // Pivot between big and small numbers, round off <1000 to integer
    const useNum = toNumber(num);
    if (useNum < 1 && useNum > 0 && showLessThanOne) {
      return '< $1';
    } else if (useNum < 0 && useNum > -1 && showLessThanOne) {
      return '> -$1';
    } else {
      try {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          ...(options || {}),
        }).format(toNumber(num));
      } catch (e) {
        return `${num}`;
      }
    }
  },

  numberToCompactCurrency: (num: number | string, options?: any, showLessThanOne = true): string => {
    // Pivot between big and small numbers, round off <1000 to integer
    const useNum = toNumber(num);
    if (useNum < 1 && useNum > 0 && showLessThanOne) {
      return '< $1';
    } else if (useNum < 0 && useNum > -1 && showLessThanOne) {
      return '> -$1';
    } else if (useNum > 999) {
      return FormatHelpers.numberToCurrency(useNum, {
        notation: 'compact',
        compactDisplay: 'short',
        trailingZeroDisplay: 'stripIfInteger',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
        roundingPriority: 'lessPrecision',
        ...options,
      });
    } else {
      return FormatHelpers.numberToCurrency(useNum, {
        notation: 'compact',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        roundingPriority: 'lessPrecision',
        ...options,
      });
    }
  },

  numberFormat: (num: number | string, options?: any): string => {
    try {
      return new Intl.NumberFormat('en-US', {
        ...(options || {}),
      }).format(toNumber(num));
    } catch (e) {
      return `${num}`;
    }
  },

  numberToCompactNumber: (num: number | string, options?: any): string => {
    // Pivot between big and small numbers, round off <1000 to integer
    const useNum = toNumber(num);
    if (useNum > 999) {
      return FormatHelpers.numberFormat(useNum, {
        notation: 'compact',
        compactDisplay: 'short',
        trailingZeroDisplay: 'stripIfInteger',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
        roundingPriority: 'lessPrecision',
        ...options,
      });
    } else {
      return FormatHelpers.numberFormat(useNum, {
        notation: 'compact',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        roundingPriority: 'lessPrecision',
        ...options,
      });
    }
  },

  feinFormat: (ein: number | string): string => {
    if (ein) {
      const tmpEIN = ein.toString();
      return `${tmpEIN.slice(0, 2)}-${tmpEIN.slice(2)}`;
    } else {
      return null;
    }
  },

  productNameFormatter: (
    productName: string,
    hmoFlag: boolean,
    ppoFlag: boolean,
    asoFlag = false,
    allowAllProducts = false
  ): string => {
    // Returns a concatenated version of a product name with HMO and PPO verbiage based on flagging (BFR-413)
    const concatenatedFlags = [asoFlag ? 'ASO' : null, hmoFlag ? 'HMO' : null, ppoFlag ? 'PPO' : null]
      .filter((x) => x != null)
      .join('+');
    return allowAllProducts || ['Health', 'Dental'].includes(productName)
      ? `${productName}${concatenatedFlags ? ` (${concatenatedFlags})` : ''}`
      : productName;
  },

  toTitleCase: (input: string): string => {
    return input && input.length
      ? input.replace(/^[-_ ]*(.)|[-_ ]+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()))
      : null;
  },

  abbreviateToLength: (input: string, length: number, truncateLength?: number): string => {
    return input?.length > (truncateLength || length)
      ? `${input?.substring(0, length)}${input?.length > length ? '.' : ''}`
      : input;
  },

  getEntityNameFromID: (id: string): string => {
    /* Returns a string name based on an ID value
     * For example, "wal-mart-stores-inc~1" should return "Wal Mart Stores Inc"
     * Intended to be used for initial page renders to prevent "Loading" or other placeholder titles (BFR-41) */
    if (id && id.length) {
      return FormatHelpers.toTitleCase(id.split('~')[0]);
    } else {
      return '';
    }
  },

  getValueFormatFunc: (valueFormat: 'currency' | 'number' | 'compactCurrency' | 'compactNumber') => {
    let valueFormatFunc = null;
    if (valueFormat) {
      switch (valueFormat) {
        case 'currency':
          valueFormatFunc = FormatHelpers.numberToCurrency;
          break;
        case 'compactCurrency':
          valueFormatFunc = FormatHelpers.numberToCompactCurrency;
          break;
        case 'number':
          valueFormatFunc = FormatHelpers.numberFormat;
          break;
        case 'compactNumber':
          valueFormatFunc = FormatHelpers.numberToCompactNumber;
          break;
      }
    }
    return valueFormatFunc;
  },

  rechartsLegendFormatter: (
    value: string | number,
    entry: any,
    index: number,
    $theme: any,
    valueFormat?: 'currency' | 'number' | 'compactCurrency' | 'compactNumber'
  ): any => {
    const valueFormatFunc = FormatHelpers.getValueFormatFunc(valueFormat);

    return (
      <LabelXSmall
        marginLeft={'4px' /* 4px here and another 4px from icon */}
        marginRight={'14px' /* 14px here and another 10px from recharts */}
        display={'inline-block'}
        overrides={{ Block: { style: { verticalAlign: 'middle' } } }}
      >
        <span
          style={{
            fontWeight: 500,
            color: $theme.colors['chartLabel'],
          }}
        >
          {!valueFormatFunc || isNaN(value as number) ? value : valueFormatFunc(value)}
        </span>
        {valueFormatFunc && !isNaN(value as number) && (
          <>
            <br />
            <span
              style={{
                paddingLeft: '18px',
                color: $theme.colors['chartLabel'],
              }}
            >
              {valueFormatFunc(value)}
            </span>
          </>
        )}
        {valueFormatFunc && !isNaN(entry?.payload?.value as number) && (
          <>
            <span>:</span>
            <span
              style={{
                paddingLeft: '8px',
                color: $theme.colors['chartLabel'],
              }}
            >
              {valueFormatFunc(entry?.payload?.value)}
            </span>
          </>
        )}
      </LabelXSmall>
    );
  },

  normalizeEmail: (email: string) => {
    if (email && typeof email === 'string' && email.length > 0) {
      // Get the first two elements only, separated by `@` from user input.
      const trimmedEmail = email.toLowerCase().trim();
      if (!trimmedEmail) {
        return null;
      }

      const [local, domain] = trimmedEmail.split('@');

      if (local && domain) {
        // The part before "@" can contain a "," but we remove it on the domain part
        return `${local}@${domain.split(',')[0]}`;
      } else {
        // Didn't get the two components, not safe to run any further logic to normalize the email
        return null;
      }
    } else {
      return null;
    }
  },
};

import {
  AppState,
  BeAvatar,
  BeButton,
  BeButtonLink,
  BeMainAppLayout,
  BeMainAppLayoutProps,
  BePopoverWithTooltip,
  BeTooltip,
  ROUTES_WITHOUT_HEADER_PROMO_BLOCK,
  setCurrentNotifications,
  setNotificationsViewed,
  store,
  toggleSidebar,
  useBreakpoint,
} from '@benefeature/shared/ui';
import { ApiEnum, MarketingUrlMap, RouteEnum, StaticRefEnum } from '@benefeature/shared-common';
import { useRouter } from 'next/router';
import SearchBoxAutocomplete from '../Search/SearchBoxAutocomplete/SearchBoxAutocomplete';
import React, { memo, useEffect, useState } from 'react';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SwitchAccessShortcutRoundedIcon from '@mui/icons-material/SwitchAccessShortcutRounded';
import { useStyletron } from 'baseui';
import { dismissAllNotifications, useUserNotifications } from '../../core/services/notifications.service';
import { useSelector } from 'react-redux';
import isEqual from 'lodash-es/isEqual';
import { signOut, useSession } from 'next-auth/react';
import { ikLogo } from '../../core/factories/image.factory';
import { trackHubSpotPageView, trackSentryUser } from '../../core/services/session.service';
import { downloadExport, downloadStaticRef } from '../../core/services/exports.service';
import ContextSelect from '../Contexts/ContextSelect';
import { Block } from 'baseui/block';
import { highestActiveSubscriptionTier } from '../../core/services/subscription.service';
import { getSubscriptionOption } from '../Signup/Subscription';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';

export type AppLayoutProps = Omit<
  BeMainAppLayoutProps,
  'notifications' | 'dismissAllNotificationsFn' | 'downloadExportFn'
>;

const buildMenuItemLabel = (name, icon, color?: string, fontWeight?: string, fontSize?: string) => {
  return (
    <span
      style={{
        ...(color ? { color: color } : {}),
        ...(fontWeight ? { fontWeight: fontWeight } : {}),
        ...(fontSize ? { fontSize: fontSize } : {}),
      }}
    >
      {name}
      <div style={{ float: 'right' }}>{icon}</div>
    </span>
  );
};

export const AppLayout = memo(
  function AppLayout({ children, ...props }: AppLayoutProps) {
    const [, /*css*/ $theme] = useStyletron();
    const router = useRouter();
    const breakpoint = useBreakpoint();

    const { data: session, status: sessionStatus, update: updateSession } = useSession();

    // Track the page view on Hubspot and the internal API whenever the route changes
    const [trackedPath, setTrackedPath] = useState<string>('');
    useEffect(() => {
      if (router.isReady && (!trackedPath || router.asPath !== trackedPath) && sessionStatus !== 'loading' && session) {
        // Set that this path is tracked before issuing the request to track it
        // It's pretty easy to get multiple triggers on this useEffect otherwise, routinely ~2 views per each page load
        setTrackedPath(router.asPath);

        // Track on Hubspot
        trackHubSpotPageView(session, router.asPath);

        // Track on Sentry
        trackSentryUser(session);

        // Track on the internal activity registry
        fetch(ApiEnum.REGISTER_USER_ACTIVITY, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            {
              // Use the full window href for the user activity log, allows for cross-environment tracking
              url: window.location.href,
            },
            null,
            2
          ),
        });
      }
    }, [router.asPath, router.isReady, session, sessionStatus, trackedPath]);

    const onMenuItemClicked = (item: any) => {
      if (item.url) {
        if (breakpoint === 'small') {
          // Collapse the mobile sidebar
          store.dispatch(toggleSidebar(false));
        }
        return router.push(item.url);
      }
      if (item.action) {
        let origin, targetPath;
        switch (item.action) {
          case 'LOGOUT':
            // Perform an in-place route replacement which will trigger SSR without refreshing the whole page
            // Strip any query params since those are often invalidated by a session change
            // and this needs to be safe with any generic page
            origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';
            targetPath = router.asPath.split('?')[0];
            return signOut({ redirect: false, callbackUrl: `${origin}${targetPath}` }).then((data) =>
              router.replace(data.url)
            );

          case 'DOWNLOAD_USER_GUIDE':
            return downloadStaticRef(StaticRefEnum.USER_GUIDE, true);

          default:
            console.warn(`Unknown action`);
        }
      }
    };

    const userMenuItems: any[] = [
      ...(session?.user?.is_admin
        ? [
            // Add the Admin link
            {
              label: buildMenuItemLabel(
                'Admin',
                <AdminPanelSettingsOutlinedIcon style={{ fontSize: '20px', verticalAlign: 'middle' }} />
              ),
              url: RouteEnum.ADMIN,
            },
          ]
        : []),
      ...(session?.user?.is_team_manager && session?.user?.team_id
        ? [
            // Add the Manage Team link for team managers
            // Directs to the team page itself
            {
              label: buildMenuItemLabel(
                'Manage Team',
                <GroupWorkOutlinedIcon style={{ fontSize: '20px', verticalAlign: 'middle' }} />
              ),
              url: RouteEnum.MANAGE_TEAM_DETAILS.replace('[team_id]', session?.user?.team_id),
            },
          ]
        : []),
      ...(session?.user?.id
        ? [
            // Add the Your User link for authenticated users
            {
              label: buildMenuItemLabel(
                'Your User',
                <PersonRoundedIcon style={{ fontSize: '20px', verticalAlign: 'middle' }} />
              ),
              url: RouteEnum.MANAGE_USER_DETAILS.replace('[uid]', session?.user?.id),
            },
          ]
        : []),
      {
        label: buildMenuItemLabel(
          'User Guide',
          <HelpOutlineRoundedIcon style={{ fontSize: '20px', verticalAlign: 'middle' }} />
        ),
        action: 'DOWNLOAD_USER_GUIDE',
      },
      {
        label: buildMenuItemLabel(
          'Support',
          <ChatBubbleOutlineRoundedIcon style={{ fontSize: '20px', verticalAlign: 'middle' }} />
        ),
        url: MarketingUrlMap.SUPPORT,
      },
      {
        label: buildMenuItemLabel(
          'Sign Out',
          <LogoutOutlinedIcon style={{ fontSize: '20px', verticalAlign: 'middle' }} />,
          $theme.colors.contentNegative
        ),
        action: 'LOGOUT',
      },
    ];

    if (
      /* Exclude admins regardless of their team's subscription status */
      !session?.user?.is_admin &&
      !ROUTES_WITHOUT_HEADER_PROMO_BLOCK.includes(router.route as RouteEnum) &&
      session?.user?.id &&
      highestActiveSubscriptionTier(session?.user?.team?.subscriptions) === 'Basic'
    ) {
      // Add the Upgrade to Pro button
      userMenuItems.unshift({
        label: buildMenuItemLabel(
          'Upgrade to Pro',
          <SwitchAccessShortcutRoundedIcon style={{ fontSize: '20px', verticalAlign: 'middle' }} />,
          $theme.colors[getSubscriptionOption('Pro').firstColor],
          '600',
          '14px'
        ),
        url: `${RouteEnum.AUTH_ACCOUNT_SETUP}?price_id=${
          process.env.NEXT_PUBLIC_PRO_ANNUAL_PRICE_ID
        }&redirect=${encodeURIComponent(router.asPath)}`,
      });
    }

    const lastSearchURL = useSelector(({ searchReducer }: AppState) => searchReducer.lastSearchURL);

    const dispatchNotifications = (notifications) => {
      store.dispatch(setCurrentNotifications(notifications));
    };

    const [isDismissing, setIsDismissing] = useState(false);
    const onDismissAllNotifications = () => {
      if (!isDismissing) {
        setIsDismissing(true);
        dismissAllNotifications(session?.user?.id)?.then(() => setIsDismissing(false));
      }
    };

    // Set up a custom hook for user notifications and run the dispatch callback whenever they change
    // Don't use the results from this directly but allow it to propagate via the AppState useSelector
    // This aligns notification usage with components which aren't able to set up a hook for this
    useUserNotifications(session?.user?.id, dispatchNotifications);

    // Hook the redux stores for notifications and establish logic to update stores based on viewed conditions
    const notifications = useSelector(
      ({ notificationsReducer }: AppState) => notificationsReducer.currentNotifications
    );
    const anyNotificationsOpen = useSelector(
      ({ notificationsReducer }: AppState) => notificationsReducer.anyNotificationsOpen
    );
    const [priorNotifications, setPriorNotifications] = useState(notifications);
    useEffect(() => {
      // Handle changes to notifications, including factoring in whether any tab has the pane opened
      if (notifications && !isEqual(notifications, priorNotifications)) {
        // The notifications have changed - mark as not viewed yet if the user doesn't have them open already
        if (!anyNotificationsOpen) {
          store.dispatch(setNotificationsViewed(false));
        }
        setPriorNotifications(notifications);
      }
    }, [notifications, anyNotificationsOpen, priorNotifications]);

    return (
      <BeMainAppLayout
        session={session}
        lastSearchURL={lastSearchURL}
        userMenu={
          session?.user
            ? {
                icon: (session?.user?.image as string) ?? '',
                // Grab the name if available, otherwise split the email on the @ symbol and use what's prior
                name: session?.user?.name?.split(' ')?.[0] || session?.user?.email?.split('@')?.[0],
                onItemClick: onMenuItemClicked,
                menuItems: userMenuItems,
                isSquare: true,
              }
            : undefined
        }
        entityContextComponent={
          session?.user ? (
            <Block style={{ cursor: 'pointer' }}>
              <BePopoverWithTooltip
                popoverProps={{
                  content: ({ close }) => (
                    <>
                      <BeButton
                        type={'button'}
                        kind={'tertiary'}
                        size={'mini'}
                        onClick={() => close()}
                        overrides={{ BaseButton: { style: { position: 'fixed', top: '8px', right: '12px' } } }}
                      >
                        Close
                      </BeButton>
                      <ContextSelect
                        userID={session?.user?.id}
                        afterChangeFn={() => {
                          updateSession();
                          close();
                        }}
                      />
                    </>
                  ),
                }}
                tooltipProps={{ content: 'Alter your viewing context to a different entity', onMouseEnterDelay: 1000 }}
              >
                {session?.user?.current_context_entity_name ? (
                  <BeAvatar
                    src={ikLogo(session?.user?.current_context_entity_domain)}
                    size={'32px'}
                    isSquare
                    noRounding
                    noBorder
                    verticalMiddle
                    name={session?.user?.current_context_entity_name}
                  />
                ) : (
                  <VisibilityOutlinedIcon
                    style={{ fontSize: '24px', verticalAlign: 'middle', color: $theme.colors.contentTertiary }}
                  />
                )}
              </BePopoverWithTooltip>
            </Block>
          ) : null
        }
        userFallbackComponent={props.userFallbackComponent}
        searchComponent={
          /* Only show the search autocomplete box if a user is logged in (doesn't need to be subscribed) */
          session?.user ? <SearchBoxAutocomplete limit={3} /> : null
        }
        promoBlock={
          /* Inject the upgrade CTA as applicable */
          session?.user?.id && highestActiveSubscriptionTier(session?.user?.team?.subscriptions) === 'Basic' ? (
            <BeTooltip content={'Upgrade to Pro access and unlock the full suite of insights and reporting available.'}>
              <BeButtonLink
                size={'default'}
                overrides={{ BaseButton: { style: { whiteSpace: 'nowrap' } } }}
                colors={{
                  color: $theme.colors[getSubscriptionOption('Pro').firstColor],
                  backgroundColor: $theme.colors.contentInversePrimary,
                }}
                href={`${RouteEnum.AUTH_ACCOUNT_SETUP}?price_id=${
                  process.env.NEXT_PUBLIC_PRO_ANNUAL_PRICE_ID
                }&redirect=${encodeURIComponent(router.asPath)}`}
                enhancerOnly={['small', 'medium', 'large'].includes(breakpoint)}
                endEnhancer={<SwitchAccessShortcutRoundedIcon fontSize={'medium'} />}
              >
                Upgrade to Pro
              </BeButtonLink>
            </BeTooltip>
          ) : null
        }
        dismissAllNotificationsFn={() => (isDismissing ? null : onDismissAllNotifications())}
        downloadExportFn={downloadExport}
        {...props}
      >
        {children}
      </BeMainAppLayout>
    );
  },
  (a, b) => isEqual(a, b)
);

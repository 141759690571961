import { useEffect, useLayoutEffect, useState } from 'react';
import { PageNavItem } from '@benefeature/shared-types';
import cloneDeep from 'lodash-es/cloneDeep';

type SectionProps = {
  id: string;
  offsetTop: number;
};

type ValidSectionsAndItemsProps = {
  sections: SectionProps[];
  pageNavItemsFiltered: PageNavItem[];
};

function getValidSectionsAndItems(pageNavItems: PageNavItem[]): ValidSectionsAndItemsProps {
  if (pageNavItems?.length) {
    /* Get item IDs for both nav items and sub nav items */
    const itemIds = [];
    pageNavItems?.forEach((item) => {
      itemIds.push(item.itemId);
      if (item?.subNav?.length) {
        item.subNav.forEach((subNavItem) => {
          itemIds.push(subNavItem.itemId);
        });
      }
    });

    const availableSections = Object.values(document.querySelectorAll(itemIds.join(', '))).map((documentSection) => {
      return {
        id: documentSection.getAttribute('id'),
        offsetTop: documentSection['offsetTop'],
      };
    });

    /* Build the set of filtered page nav items based on which item IDs are actually on the page */
    const filteredNavItems = [];
    pageNavItems?.forEach((item) => {
      const modifiedItem = cloneDeep(item);

      // Check if the top-level nav item ID exists
      let itemIdExists = false;
      availableSections.forEach((availableSection) => {
        if (modifiedItem.itemId === `#${availableSection.id}`) {
          itemIdExists = true;
        }
      });

      // If it does exist, enter the sub-process
      if (itemIdExists) {
        // Manipulate the subNav as necessary
        if (modifiedItem?.subNav?.length) {
          modifiedItem.subNav = modifiedItem.subNav.filter((subNavItem) => {
            let subItemIdExists = false;
            availableSections.forEach((availableSection) => {
              if (subNavItem.itemId === `#${availableSection.id}`) {
                subItemIdExists = true;
              }
            });
            return subItemIdExists;
          });
        }

        // Push the modified item to the filtered array
        filteredNavItems.push(modifiedItem);
      }
    });

    /* Return the filtered item IDs based on which sections exist and HTML sections based on all item IDs */
    return {
      sections: availableSections,
      pageNavItemsFiltered: filteredNavItems,
    };
  } else {
    // Default to no page nav items and sections
    return {
      sections: null,
      pageNavItemsFiltered: [],
    };
  }
}

// Prevents issues with SSR triggering a mismatched unhydrated variant
const useSSRSafeLayoutEffect = typeof window === 'undefined' ? () => null : useLayoutEffect;

// Provides a safe document construct
const useDocument = typeof document === 'undefined' ? null : document;

export function useSideNav(pageNavItems: PageNavItem[]): ValidSectionsAndItemsProps {
  const [sections, setSections] = useState<SectionProps[]>(null);
  const [pageNavItemsFiltered, setPageNavItemsFiltered] = useState<PageNavItem[]>(null);

  useEffect(() => {
    console.debug(useSideNav.name, ': New page nav items filtered set:', pageNavItemsFiltered);
  }, [pageNavItemsFiltered]);

  useSSRSafeLayoutEffect(() => {
    const validSectionsAndItems = getValidSectionsAndItems(pageNavItems);
    setSections(validSectionsAndItems.sections);
    setPageNavItemsFiltered(validSectionsAndItems.pageNavItemsFiltered);
  }, [pageNavItems, useDocument?.body?.scrollHeight]);

  return {
    sections,
    pageNavItemsFiltered,
  };
}

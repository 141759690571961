import { UserRole } from '@benefeature/shared-types';
import { PERMISSIONS, SUBSCRIPTIONS } from '@benefeature/shared-common';
import { Session } from 'next-auth';

export function isAuthenticated(session: Session | null | undefined) {
  return session?.user?.id;
}

export function isAdmin(session: Session | null | undefined) {
  return meetsSomeRole([{ hasPermissions: [PERMISSIONS.SYSTEM_ADMIN] }], session);
}

export function enterpriseOrAdmin(session: Session | null | undefined) {
  return meetsSomeRole(
    [{ hasSubscriptions: [SUBSCRIPTIONS.ENTERPRISE] }, { hasPermissions: [PERMISSIONS.SYSTEM_ADMIN] }],
    session
  );
}

export function subscribedOrAdmin(session: Session | null | undefined) {
  return meetsSomeRole(
    [
      { hasSubscriptions: [SUBSCRIPTIONS.PRO] },
      { hasSubscriptions: [SUBSCRIPTIONS.ENTERPRISE] },
      { hasPermissions: [PERMISSIONS.SYSTEM_ADMIN] },
    ],
    session
  );
}

function _hasPermission(permission: PERMISSIONS, claims: Record<string, unknown>): boolean {
  // Map all permissions defined to a check against auth claims
  switch (permission) {
    case PERMISSIONS.SYSTEM_ADMIN:
      return !!claims?.isAdmin;

    case PERMISSIONS.TEAM_MANAGER:
      return !!claims?.isTeamManager;

    case PERMISSIONS.IS_AUTHENTICATED:
      return Object.keys(claims ?? {}).length > 0;
  }

  // Default to false
  return false;
}

function _hasSubscription(subscription: SUBSCRIPTIONS, sessionClaims: Record<string, unknown>): boolean {
  // Map all subscriptions defined to a check against user claims
  switch (subscription) {
    case SUBSCRIPTIONS.PRO:
      return sessionClaims?.currentSubscription === 'Pro';

    case SUBSCRIPTIONS.ENTERPRISE:
      return sessionClaims?.currentSubscription === 'Enterprise';
  }

  // Default to false
  return false;
}

export function meetsSomeRole(rolesToCheck: UserRole[], session: Session | null | undefined): boolean {
  // Verify some roles were provided
  // If not, return true as no stipulations were provided
  if (!rolesToCheck || !rolesToCheck.length) {
    return true;
  }

  // Return false if there's no session or there's no user
  if (!session || !session?.user) {
    return false;
  }

  // Return false if the user isn't active
  if (!session?.user?.is_active) {
    return false;
  }

  // Verify some claims were provided to validate against
  // todo someday refactor this out since all users have some sort of claims, directly validate properties instead
  //     note that several claims should have supplemental information pulled anyway, such as subscriptions needing to be active and current
  if (session?.user?.claims) {
    // Check whether some roles are met by this auth's claims
    return rolesToCheck?.some((role) => {
      // Run the checks for this role
      return (
        (!role.hasPermissions ||
          !role.hasPermissions.length ||
          role.hasPermissions.every((permission) => _hasPermission(permission, session?.user?.claims))) &&
        (!role.hasSubscriptions ||
          !role.hasSubscriptions.length ||
          role.hasSubscriptions.every((subscription) => _hasSubscription(subscription, session?.user?.claims)))
      );
    });
  }

  // Fall back to false
  return false;
}

import * as React from 'react';
import { useBreakpoint, useDebugAttrs } from '../../hooks';
import { HeadingMedium, LabelLarge, LabelMedium } from 'baseui/typography';
import { styled, useStyletron } from 'baseui';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CopyrightRoundedIcon from '@mui/icons-material/CopyrightRounded';
import { ContactEnum, MarketingUrlMap, RouteEnum } from '@benefeature/shared-common';
import Link from 'next/link';
import { ChatBubbleOutlineRounded } from '@mui/icons-material';
import { Block } from 'baseui/block';
import { memo } from 'react';

const FooterHeadingMedium = styled(HeadingMedium, ({ $theme }) => ({
  color: `${$theme.colors.contentInverseSecondary} !important`,
  marginBottom: '12px',

  /* Prevent wrapping of whitespace and try to ellipsize */
  display: '-webkit-box',
  maxWidth: '80vw',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'break-spaces',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  overflowY: 'hidden',
}));

const FooterLabelLarge = styled(LabelLarge, ({ $theme }) => ({
  color: `${$theme.colors.contentInverseSecondary} !important`,
  marginTop: 0,
  marginBottom: '10px',
  display: 'flex',
  gridColumnGap: '8px',
  alignItems: 'center',
  /* Wrap the label content if we're on a very narrow viewport */
  flexWrap: 'wrap',
}));

const FooterLabelText = styled(Block, ({ $theme }) => ({
  /* Prevent wrapping of whitespace and try to ellipsize */
  display: '-webkit-box',
  maxWidth: '100%',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'break-spaces',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  overflowY: 'hidden',
}));

export const BeAppFooter = memo(function BeAppFooter() {
  const [section] = useDebugAttrs('BeAppFooter');
  const [, /*css*/ theme] = useStyletron();
  const breakpoint = useBreakpoint();
  const isWindowDefined = typeof window !== 'undefined';

  return (
    <Block paddingBottom={'80px'} backgroundColor={theme.colors.backgroundInverseSecondary} {...section('root')}>
      <Block
        display={'flex'}
        gridColumnGap={'16px'}
        justifyContent={'space-around'}
        {...(['small', 'medium'].includes(breakpoint) ? { marginLeft: '20vw' } : {})}
        flexDirection={['small', 'medium'].includes(breakpoint) ? 'column' : 'row'}
      >
        <Block>
          <FooterHeadingMedium>Contact Us</FooterHeadingMedium>
          {isWindowDefined && (window as any)?.HubSpotConversations?.widget?.status()?.loaded ? (
            <Block marginLeft={'20px'}>
              <a
                onClick={() => {
                  // Explicitly cast window to any in order to force the otherwise-unknown HubSpotConversations to pass linting
                  const anyWindow = window as any;
                  anyWindow?.HubSpotConversations?.widget?.open();
                }}
              >
                <FooterLabelLarge>
                  <ChatBubbleOutlineRounded /> <FooterLabelText>Live Chat</FooterLabelText>
                </FooterLabelLarge>
              </a>
            </Block>
          ) : null}

          <Block marginLeft={'20px'}>
            <a /* Special onclick handler to open the mailto in a new tab/window if possible */
              onClick={(event) => {
                window.open(`mailto:${ContactEnum.HELLO_EMAIL}`, 'mail');
                event.preventDefault();
              }}
              href={`mailto:${ContactEnum.HELLO_EMAIL}`}
            >
              <FooterLabelLarge>
                <EmailOutlinedIcon /> <FooterLabelText>{ContactEnum.HELLO_EMAIL}</FooterLabelText>
              </FooterLabelLarge>
            </a>
          </Block>

          <Block marginLeft={'20px'}>
            <a
              target={'_blank'}
              rel={'noreferrer'}
              href={'https://www.linkedin.com/company/benefeature'}
              style={{ color: theme.colors.contentInverseSecondary }}
            >
              <FooterLabelLarge>
                <LinkedInIcon /> <FooterLabelText>LinkedIn</FooterLabelText>
              </FooterLabelLarge>
            </a>
          </Block>
        </Block>

        <Block>
          <FooterHeadingMedium>Info</FooterHeadingMedium>
          <Block marginLeft={'20px'}>
            <Link
              prefetch={false}
              href={RouteEnum.KEY_STATISTICS}
              style={{ color: theme.colors.contentInverseSecondary }}
            >
              <FooterLabelLarge>
                <FooterLabelText>Key Insurance Statistics</FooterLabelText>
              </FooterLabelLarge>
            </Link>
          </Block>

          <Block marginLeft={'20px'}>
            <Link
              prefetch={false}
              href={RouteEnum.KNOWLEDGE_BASE}
              style={{ color: theme.colors.contentInverseSecondary }}
            >
              <FooterLabelLarge>
                <FooterLabelText>Knowledge Base</FooterLabelText>
              </FooterLabelLarge>
            </Link>
          </Block>

          <Block marginLeft={'20px'}>
            <Link
              prefetch={false}
              href={MarketingUrlMap.SUPPORT}
              style={{ color: theme.colors.contentInverseSecondary }}
            >
              <FooterLabelLarge>
                <FooterLabelText>Support</FooterLabelText>
              </FooterLabelLarge>
            </Link>
          </Block>

          <Block marginLeft={'20px'}>
            <Link
              prefetch={false}
              href={MarketingUrlMap.PRICING}
              style={{ color: theme.colors.contentInverseSecondary }}
            >
              <FooterLabelLarge>
                <FooterLabelText>Pricing</FooterLabelText>
              </FooterLabelLarge>
            </Link>
          </Block>
        </Block>

        <Block>
          <FooterHeadingMedium>Policies</FooterHeadingMedium>
          <Block marginLeft={'20px'}>
            <Link
              prefetch={false}
              href={MarketingUrlMap.TERMS_OF_SERVICE}
              style={{ color: theme.colors.contentInverseSecondary }}
            >
              <FooterLabelLarge>
                <FooterLabelText>Terms of Service</FooterLabelText>
              </FooterLabelLarge>
            </Link>
          </Block>

          <Block marginLeft={'20px'}>
            <Link
              prefetch={false}
              href={MarketingUrlMap.PRIVACY_POLICY}
              style={{ color: theme.colors.contentInverseSecondary }}
            >
              <FooterLabelLarge>
                <FooterLabelText>Privacy</FooterLabelText>
              </FooterLabelLarge>
            </Link>
          </Block>
        </Block>
      </Block>

      <Block display={'flex'} justifyContent={'flex-end'}>
        <LabelMedium
          marginTop={'20px'}
          marginLeft={'20px'}
          marginRight={'40px'}
          marginBottom={'40px'}
          color={theme.colors.contentInverseSecondary}
          display={'flex'}
          alignItems={'center'}
          gridColumnGap={'8px'}
        >
          <CopyrightRoundedIcon /> {new Date().getFullYear()} MarketShare LLC All Rights Reserved
        </LabelMedium>
      </Block>
    </Block>
  );
});

import { ReactNode } from 'react';
import { useBreakpoint, useDebugAttrs } from '../../hooks';
import { BaseWebHelpers } from '../../helpers';
import { Block } from 'baseui/block';
import { useStyletron } from 'baseui';

export type BeAppSecondaryHeaderProps = {
  leftChildren?: ReactNode;
  centerChildren?: ReactNode;
  rightChildren?: ReactNode;
};

export const BeAppSecondaryHeaderHeight = 72;

export const BeAppSecondaryHeader = ({ leftChildren, rightChildren, centerChildren }: BeAppSecondaryHeaderProps) => {
  const [section] = useDebugAttrs(BeAppSecondaryHeader.name);
  const [, /* css */ $theme] = useStyletron();

  const breakpoint = useBreakpoint();
  const smallView = breakpoint === 'small';

  return (
    <Block
      {...section('root')}
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      gridColumnGap={'16px'}
      overrides={{
        Block: {
          style: {
            flexWrap: 'nowrap !important',
            height: `${BeAppSecondaryHeaderHeight}px`,
            maxHeight: `${BeAppSecondaryHeaderHeight}px`,
            maxWidth: '100vw',
            ...BaseWebHelpers.padding('0'),
            backgroundColor: '#fff',
            borderBottomWidth: '2px',
            borderBottomColor: $theme.colors.borderOpaque,
            borderBottomStyle: 'solid',
          },
        },
      }}
    >
      <Block
        {...section('left-children')}
        display={'flex'}
        flexDirection={'row'}
        overrides={{
          Block: {
            style: {
              flexWrap: 'nowrap !important',
              height: `${BeAppSecondaryHeaderHeight}px`,
              maxHeight: `${BeAppSecondaryHeaderHeight}px`,
            },
          },
        }}
      >
        {leftChildren}
      </Block>

      <Block
        {...section('center-children')}
        display={'flex'}
        flexDirection={'row'}
        overflow={'hidden'}
        overrides={{
          Block: {
            style: {
              flexWrap: 'nowrap !important',
              height: `${BeAppSecondaryHeaderHeight}px`,
              maxHeight: `${BeAppSecondaryHeaderHeight}px`,

              ...(smallView
                ? {
                    paddingLeft: 0,
                    paddingRight: 0,
                  }
                : {}),
            },
          },
        }}
      >
        {centerChildren}
      </Block>

      <Block
        {...section('right-children')}
        display={'flex'}
        flexDirection={'row'}
        overrides={{
          Block: {
            style: {
              flexWrap: 'nowrap !important',
              height: `${BeAppSecondaryHeaderHeight}px`,
              maxHeight: `${BeAppSecondaryHeaderHeight}px`,

              flexShrink: 0,
              paddingRight: '12px',
            },
          },
        }}
      >
        {rightChildren}
      </Block>
    </Block>
  );
};
